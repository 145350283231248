import React from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Downloadable from '../../components/common/Downloadable'
import Layout from '../../components/common/Layout'

export default function ResearchPV() {
    return (
        <Layout
        active='research-polycythemia-vera'
        title='PharmaEssentia Medical Affairs | Areas of Research'
        description='Educational information relating to PharmaEssentia’s therapeutic areas of interest, including polycythemia vera, essential thrombocythemia, and interferon use in myeloproliferative neoplasms.'
        socialTitle='PharmaEssentia Medical Affairs | Areas of Research'
        socialDescription='Educational information relating to PharmaEssentia’s therapeutic areas of interest, including polycythemia vera, essential thrombocythemia, and interferon use in myeloproliferative neoplasms.'
        pathname='/research'
        >
            <section>
                <div className='inner'>
                    <h1>Areas of Research</h1>
                    <p>This section contains educational information relating to PharmaEssentia’s therapeutic areas of interest.</p>
                    <SegmentedControl buttons='areas-of-research' active='research-polycythemia-vera'/>
                    <h3>Polycythemia Vera (PV)</h3>
                    <p>PV is a rare, chronic myeloproliferative neoplasm (MPN) characterized by an increased proliferation of hematopoietic stem and progenitor cells, resulting in erythrocytosis, leukocytosis, and thrombocytosis.</p>
                    <p>PV is driven by a mutation in the <i>JAK2</i> gene, which leads to the proliferation of hematopoietic stem cells in the bone marrow and results in thickening of the blood and an increased risk of hemorrhage, thrombosis, cardiovascular events, and other debilitating symptoms.</p>
                    <p>The diagnostic criteria of PV incorporate the significance of both clinical and molecular findings. The WHO criteria have been updated in 2016 to provide a better understanding of the morphological features of PV, which can help address the possibility of underdiagnosis. The 2022 ICC MPN subcommittee also published recommended updates to these diagnostic criteria.<br/><br/>The goal of comprehensive disease management in PV is to achieve control of blood counts, possible molecular response, symptom management, and prevent progression and transformation. Current therapies include <i>JAK</i> inhibitors, cytoreductive agents, and interferon alfa.</p>
                </div>
            </section>
        </Layout>
    )
}